/**
 * @description Punto de entrada de estilos, carga Tailwindcss
 */

@tailwind base;
@tailwind components;
@tailwind utilities;

.input__react__select__control {
  @apply !w-48 !border-0 !shadow-none;
  height: 24px !important;
  min-height: 24px !important;
}

.input__react__select__control:hover {
  @apply !border-0 !border-transparent !shadow-none;
}

.input__react__select__value-container {
  @apply !p-0;
}

.input__react__select__input-container {
  @apply !p-0 !m-0;
}

.input__react__select__placeholder {
  @apply !text-black !text-sm !font-medium;
}

.input__react__select__indicator.input__react__select__dropdown-indicator {
  @apply !p-0 !pl-2 !text-gray-600;
}
